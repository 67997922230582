
import { Box } from '@material-ui/core';
import { ThirdPersonView } from './styles';

export function ThirdPersonScreen() {
  
  return <Box>
    <ThirdPersonView />
  </Box>;
}
