import { ValidAmount } from './ValidAmount';
import { InvalidAmount } from './InvalidAmount';
import { InvalidUserData } from './InvalidUserData';
import { usePaymentContext } from 'context/PaymentContext';
import { ThirdPersonScreen } from './ThirdPersonScreen';
import { selectUnicoPay } from 'reducers/unicopay';
import { useSelector } from 'react-redux';


export function ShowBanner({handlePay}) {
  const  {userData, amount} = usePaymentContext()
  const { shared } = useSelector(selectUnicoPay)

  if (shared) {
    return <ThirdPersonScreen />
  } else if (Object.keys(userData).length > 0) {
    if (!amount) {
      return <InvalidAmount />;
    }
    else {
      return <ValidAmount handlePay={handlePay} />;
    }
  } else {
    return <InvalidUserData />;
  }
}
