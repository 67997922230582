import clsx from 'clsx'
import { styled, withStyles } from '@material-ui/core/styles'
import { Box, Button, IconButton, SvgIcon, Typography, useMediaQuery } from '@material-ui/core'
import { ReactComponent as LogoIcon } from 'assets/logo-colored.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon-download.svg'
import { ReactComponent as AppleStoreIcon } from 'assets/apple-store-icon.svg'
import { ReactComponent as GooglePlayIcon } from 'assets/google-play-icon.svg'
import { ReactComponent as CopyIcon } from 'assets/icon-copy.svg'
import { ReactComponent as CheckIcon } from 'assets/icon-check.svg'

import anotherBrowserImage from 'assets/another-browser.png'

import config from 'config'

import bgXs from 'assets/bg-desktop@3x.jpg'
import bgMd from 'assets/bg-mobile@3x.jpg'
import bannerImage from 'assets/payment-link@3x.png'
import BannerLink from 'components/ui/BannerLink'
import backgroundVisaMobile from 'assets/banner-visa-mobile.png'
import backgroundVisa from 'assets/banner-visa.png'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'
import ELEMENT_TEST_ID from '/playwright/support/elements';
import { usePaymentContext } from 'context/PaymentContext'
import { useState } from 'react'
import useRudderStack from 'utils/useRudderStack'

export const HeaderBox = withStyles(
  (theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100vh',
      flex: '1 0 auto',
      flexDirection: 'column',
      transition:
        'background-position .369s ease-out, visibility 0.369s ease-out, opacity 0.369s ease-out',
      
    },
    shrink: {
      backgroundPosition: `center 40%`,
      '& .pay-container': {
        opacity: 0,
        visibility: 'hidden',
        maxHeight: 0,
      },
      '&, html.mobile &': {
        height: 0,
        visibility: 'hidden',
        opacity: 0,
        [theme.breakpoints.up('md')]: {
          backgroundPosition: `center 0%`,
          transitionDuration: '0s, 0s',
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
  }),
  {
    name: 'HeaderBox',
  },
)(({ shrink, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.shrink]: !shrink })} {...props} />
))

export const TransactionView = withStyles(
  ({ theme, ...props }) => ({
    root: {
      transition: 'height 0.369s ease-in-out',
      height: 148,
      '& > div': {
        transition: 'opacity 0.369s ease-in-out, transform 0.369s ease-in-out',
      },
    },
    shrink: {
      height: 0,
      '& > div': {
        opacity: 0,
        transform: 'scale(0.8)',
      },
    },
  }),
  {
    name: 'TransactionView',
  },
)(({ shrink, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.shrink]: shrink })} {...props} />
))

export const BannerButton = styled(Button)(
  ({ theme, ...props }) => {
    return {
      marginTop: '13px !important',
      fontWeight: 700,
      fontSize: '9.03px !important',
      height: '25.44px !important',
      width: '140.28px !important',
      '&:hover': {
        transition: 'transform 0.7s ease-in-out',
      },
    }
  },
  {
    name: 'BannerButton',
  },
)

export const FlowMarketingBanner = withStyles(
  ({ theme, ...props }) => {
    return {
      root: {
        textDecoration: 'none',
        transition: '0.369s ease-in-out',
        opacity: 1,
        borderRadius: 8,
        visibility: 'visible',
        position: 'relative',
        width: '100%',
        margin: "0 auto",
        height: 0,
        paddingBottom: '23.84%',
        background: `transparent url(${backgroundVisa.src}) no-repeat center`,
        backgroundSize: 'auto 100%',
      },
      textContainer: {
        textAlign: 'left',
        marginLeft: '6%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3.2%',
      },
      textContainerActive: {
        textAlign: 'left',
        marginLeft: '100px',
        display: 'flex',
        flexDirection: 'column',
        height: '190px',
        justifyContent: 'center',
      },
      textTypography: {
        fontWeight: 500,
        display: 'inline-block',
        fontSize: '16px',
        lineHeight: '21.23px',
        color: '#000000',
      },
      textTypographyActive: {
        fontWeight: 500,
        display: 'inline-block',
        fontSize: '12px',
        color: '#000000',
      },
      textTypographyTitle: {
        fontSize: '20px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        height: '39px',
        color: '#000000',
        width: '320px',
      },
      textTypographyTitleActive: {
        fontSize: '18px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        minHeight: '30px',
        color: '#000000',
        marginRight: '250px',
      },
      button: {
        background: '#000000 !important',
        color: '#FFFFFF !important',
        transition: 'all 0.3s ease-in-out',
        fontSize: '12px !important',
        height: '30px !important',
        fontWeight: 700,
        '&:hover': {
          transform: 'scale(0.94)',
          background: '#000000 !important',
        },
      },
    }
  },
  {
    name: 'FlowMarketingBanner',
  },
)(({ shrink, classes, ...props }) => {
  const payments = useSelector(selectPayments)

  return (
    <BannerLink
      href={shrink ? config.infinitePayLinkBanner.desktop : config.infinitePayLinkBanner.home}
      className={clsx(classes.root, { [classes.shrink]: shrink })}
      data-testid={ELEMENT_TEST_ID.homeBannerUrl}
      {...props}
    >
      <Box className={!payments.active ? classes.textContainer : classes.textContainerActive}>
        <Typography
          className={clsx(
            !payments.active ? classes.textTypographyTitle : classes.textTypographyTitleActive,
          )}
        >
          Link de Pagamento InfinitePay
        </Typography>
        <Typography
          className={clsx(!payments.active ? classes.textTypography : classes.textTypographyActive)}
        >
          Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
        </Typography>
        <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
      </Box>
    </BannerLink>
  )
})

export const FlowMarketingBannerMobile = withStyles(
  ({ theme, ...props }) => ({
    root: {
      textDecoration: 'none',
      transition: '0.369s ease-in-out',
      opacity: 1,
      borderRadius: 13,
      visibility: 'visible',
      position: 'relative',
      margin: "20px auto",
      width: 'calc(100%)',
      height: 150,
      paddingBottom: '23.84%',
      background: `transparent url(${backgroundVisaMobile.src}) no-repeat center`,
      backgroundSize: '100%',
    },
    textContainer: {
      textAlign: 'left',
      marginLeft: '4%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8%',
    },
    textTypography: {
      fontWeight: 500,
      display: 'inline-block',
      fontSize: '9px',
      lineHeight: '14px',
      color: '#000000',
      width: '171px',
    },
    textTypographyTitle: {
      fontSize: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      color: '#000000',
      width: '180px',
      lineHeight: '18px',
    },
    button: {
      background: '#000000 !important',
      color: '#FFFFFF !important',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(0.94)',
        background: '#000000 !important',
      },
      height: '21px !important',
      width: '118px !important',
      fontSize: '8px !important',
      marginTop: '5px !important',
    },
  }),
  {
    name: 'FlowMarketingBanner',
  },
)(({ classes, invalid, ...props }) => {
  return (
    <BannerLink
      href={config.infinitePayLinkBanner.home}
      data-testid={ELEMENT_TEST_ID.homeBannerUrl}
      className={clsx(classes.root, invalid ? classes.invalidBannerMargin : classes.validBannerMargin)}
      {...props}
    >
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.textTypographyTitle)}>
          Link de Pagamento InfinitePay
        </Typography>
        <Typography className={clsx(classes.textTypography)}>
          Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
        </Typography>
        <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
      </Box>
    </BannerLink>
  )
})

export const UpperView = styled(Box)(
  ({ theme, ...props }) => ({
    display: 'flex',
    width: 680,
    height: 148,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    borderRadius: 8,
    alignItems: 'center',
    padding: theme.spacing(2),
    background: `transparent url(${bgMd.src}) no-repeat center`,
    backgroundSize: '100% auto',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${bgXs.src})`,
      backgroundSize: '100% auto',
    },
    marginTop: 90,
    '& img': {
      marginTop: 0,
    },
    '& h1': {
      color: '#EEEEEE',
      fontSize: '1.5em',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginTop: -30,
      },
    },
    '& h6': {
      color: '#EEEEEE',
      fontSize: '1.125em',
      textAlign: 'left',
      maxWidth: 220,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        color: '#E0E0E0',
        fontSize: 14,
        maxWidth: 200,
        margin: '0 auto',
        lineHeight: '18px',
      },
    },
    '& .MuiButton-root': {
      [theme.breakpoints.up('xx')]: {
        marginTop: '7px !important',
        marginBottom: 10,
      },
      [theme.breakpoints.up('md')]: {
        
        marginTop: theme.spacing(3.5),
      },
      width: 279,
      background: '#fff',
      color: '#121212',
      boxShadow: 'none',
    },
    '& .MuiButton-root.button-gray': {
      background: '#424242',
      color: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'block',
      margin: '0px 20px 0px 20px',
      borderRadius: '4px',
    },
  }),
  {
    name: 'UpperView',
  },
)

export const DisplayBox = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.common.white,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      flex: '0 0 auto',
      margin: '0 auto',
      
      height: 'calc(100vh - 74px)',
      
      
      '& .MuiBox-root, & .MuiButton-root': {
        color: 'currentColor',
      },
      '& > h1': {
        marginTop: 0,
      },
      '& h6': {
        opacity: 0.8,
        marginBottom: theme.spacing(1),
      },
      '& h4': {
        'font-feature-settings': "'tnum' on, 'lnum' on",
        fontWeight: 500,
        marginBottom: theme.spacing(1),
      },
      '& .pay-container': {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        'will-change': 'filter',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.369s ease-in-out',
      },
      '& .pay-container.loading': {
        opacity: 0.5,
        pointerEvents: 'none',
        filter: 'blur(5px)'
      },
      '& .MuiButton-root': {
        
        width: 279,
        background: '#fff',
        color: '#121212',
        boxShadow: 'none',
        [theme.breakpoints.up('md')]: {
          width: 470
        },
        
      },
      '& .MuiButton-root.secundary' : {
        background: 'rgba(50, 50, 50, 1)',
        color: 'rgba(245, 245, 245, 1)',
      },
      '& .MuiButton-root.button-gray': {
        background: '#424242',
        color: '#fff',
      },
      [theme.breakpoints.down('sm')]: {
        flex: 'auto',
        width: 'fit-content',
      },
    },
    shrink: {
      height: 'calc(100vh)',
      [theme.breakpoints.down('sm')]: {
        '& h6': {
          fontSize: '0.85em',
          marginBottom: theme.spacing(0.5),
        },
        '& h4': {
          fontSize: '1.4285714286em',
          marginBottom: 0,
        },
      },
    },
  }),
  {
    name: 'DisplayBox',
  },
)(({ shrink, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.shrink]: shrink })} {...props} />
))

export const Bar = styled(Box)(
  ({ theme, ...props }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  }),
  {
    name: 'HeaderBar',
  },
)

export const Logo = styled((props) => (
  <SvgIcon component={LogoIcon} viewBox="0 0 178 36" {...props} />
))(
  ({ theme, ...props }) => ({
    padding: 0,
    width: '7.5em',
    height: '1.5em',
  }),
  {
    name: 'Logo',
  },
)

export const HelpButton = styled(Button)(({ theme, ...props }) => ({
  color: theme.palette.text.light,
  backgroundColor: theme.palette.grey['800'],
  height: theme.spacing(4),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}))

export const BannerBox = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: theme.palette.common.white,
      background: '#212121',
      flex: '0 0 auto',
      width: 680,
      position: 'relative',
      height: 'auto',
      padding: 20,
      borderRadius: 8,
      transition: 'all 0.368s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: 'auto',
        display: 'block',
        maxWidth: 350,
        margin: 20,
      },
    }
  },
  {
    name: 'BannerBox',
  },
)

export const BannerBox1 = styled(Box)(
  ({ theme, ...props }) => {
    return {
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: 'auto',
        display: 'block',
        maxWidth: 350,
        background: '#212121',
        borderRadius: 8,
        padding: 20,
        margin: '100px 20px 20px 20px',
      },
    }
  },
  {
    name: 'BannerBox1',
  },
)

export const BoxImage = styled(Box)(
  ({ theme, ...props }) => {
    return {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-15px',
      },
    }
  },
  {
    name: 'BoxImage',
  },
)

export const BannerImage = styled((props) => {
  return (
    <div {...props}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <img src={bannerImage.src} width={184} height={132} lowsrc={bannerImage.blurDataUrl} alt="" />
    </div>
  )
})(({ theme, ...props }) => ({
  position: 'relative',
  width: '25%',
  height: 'auto',
  '& img': {
    position: 'absolute',
    width: 'auto',
    height: '140%',
    right: '-90%',
    bottom: '-21px',
    [theme.breakpoints.up('md')]: {
      right: '-20px',
    },
  },
}))

export const BannerText = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    maxWidth: 300,
    fontSize: '16px',
    textAlign: 'left',
    display: 'block',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '0 auto',
      maxWidth: 250,
      fontSize: '16px',
    },
  }),
  {
    name: 'BannerText',
  },
)

export const BannerDullText = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    maxWidth: 300,
    fontSize: '14px',
    textAlign: 'left',
    color: '#BDBDBD',
  }),
  {
    name: 'BannerText',
  },
)

export const BannerText1 = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    maxWidth: 300,
    fontSize: '36px',
    fontWeight: 500,
    display: 'block',

    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      maxWidth: 250,
      fontSize: '36px',
    },
  }),
  {
    name: 'BannerText1',
  },
)

export const PaymentInfoTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#E0E0E0',
      margin: 0,
      fontWeight: 500,
      fontSize: '18px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    }
  },
  {
    name: 'PaymentInfoTitle',
    component: 'h2',
  },
)

export const PaymentInfoHandle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#F5F5F5',
      margin: 0,
      fontWeight: 500,
      fontSize: '26px',
      textAlign: 'center',
      width: '100%',
      whitwSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    }
  },
  {
    name: 'PaymentInfoHandle',
    component: 'h2',
  },
)

export const PaymentInfoFantasyName = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#EEEEEE',
      margin: 0,
      fontWeight: 400,
      fontSize: '14px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    }
  },
  {
    name: 'PaymentInfoFantasyName',
    component: 'h2',
  },
)

export const PaymentInfoDocumentNumber = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#9e9e9e',
      margin: 0,
      fontWeight: 500,
      fontSize: '12px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    }
  },
  {
    name: 'PaymentInfoDocumentNumber',
    component: 'h2',
  },
)
export const PaymentInfoInstallments = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#9e9e9e !important',
      margin: 0,
      fontWeight: 500,
      fontSize: '12px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    }
  },
  {
    name: 'PaymentInfoInstallments',
    component: 'h3',
  },
)

export const PaymentInfoSubTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#9e9e9e',
      margin: 0,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.3rem',
      textAlign: 'center',
      wordBreak: 'break-all',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '23.4px',
      },
    }
  },
  {
    name: 'PaymentInfoSubTitle',
    component: 'h3',
  },
)

export const BannerText2 = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    fontSize: '14px',
    color: '#9E9E9E',
    textAlign: 'center',
  }),
  {
    name: 'BannerText2',
  },
)

export const BannerText3 = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    fontSize: '18px',
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  }),
  {
    name: 'BannerText3',
  },
)

export const BannerText4 = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    fontSize: '16px',
    color: '#FFFFFF',
    lineHeight: '16px',
    maxWidth: 210,
    textAlign: 'center',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '0 auto 15px auto',
      maxWidth: 250,
    },
  }),
  {
    name: 'BannerText4',
  },
)

export const BannerText5 = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    fontSize: '14px',
    color: '#FFFFFF',
    textAlign: 'left',
    lineHeight: '16px',
    display: 'block',
    margin: '0 0 5px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '& strong': {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  }),
  {
    name: 'BannerText5',
  },
)

export const VerticalLine = styled((props) => <span {...props} />)(
  ({ theme, ...props }) => ({
    width: 1,
    height: 180,
    background: '#424242',
  }),
  {
    name: 'VerticalLine',
  },
)

export const DownloadButton = styled(({ theme, children, href, ...props }) => {
  return (
    <Button
      component="a"
      disableElevation
      variant="contained"
      target="_top"
      href={href}
      startIcon={<SvgIcon component={DownloadIcon} viewBox="0 0 32 32" />}
      {...props}
    >
      {children}
    </Button>
  )
})(
  ({ theme, ...props }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(2),
    height: 44,
  }),
  {
    name: 'DownloadButton',
  },
)

export const StoreButton = withStyles(
  (theme) => ({
    root: {
      padding: 0,
      display: 'inline-block',
      margin: `0px -29px 0px 40px`,
    },
  }),
  {
    name: 'StoreButton',
  },
)(({ theme, store, children, ...props }) => (
  <IconButton {...props}>{store === 'apple' ? <AppleStoreIcon /> : <GooglePlayIcon />}</IconButton>
))

export const QRCode = styled(Box)(
  ({ theme, ...props }) => ({
    width: 70,
    height: 70,
    '& img': {
      borderRadius: '0.2rem',
    },
  }),
  {
    name: 'QRCode',
  },
)

export const WarningWebView = withStyles(
  ({ theme, ...props }) => ({
    root: {
      'font-family': 'CeraPro, -apple-system'
    },
    positionBox: {
      position: 'fixed',
      top: 0,
      left: 0, 
      right: 0,
      bottom: 0,
      color: '#616161',
      margin: '0 -10px',
      fontWeight: '400',
      fontSize: '14px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 9999,
      backgroundColor: '#FFF',
    },
    textContainer: {
      flex: 1, 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      float: 'left',
    },
    warningTitle: {
      margin: 0,
      fontWeight: 700,
      fontSize: '24px',
      color: '#121212',
      marginBottom: 8,
    },
    warningText: {
      margin: 0,
      fontWeight: 400,
      fontSize: '16px',
      color: '#616161',
    },
    inputContainer: {
      display: 'flex',
      height: 80,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 8,
    },
    input: {
      flex: 1,
      padding: 8,
      borderRadius: 8,
      border: '1px solid #000000',
    },
    button: {
      flex: 1,
      height: 48,
      display:  'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 80,
      fontWeight: 500,
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: '16px',
      appearance: 'none',
      marginLeft: 4,
      borderRadius: 32,
      transition: 'all 0.1s ease-in-out',
      '&:active' : {
        transform: 'scale(1.04)',
      }
    },
    fakeInput: {
      marginTop: 16,
      width: '90%',
      backgroundColor: '#F5F5F5',
      padding: '8px 16px',
      height: 48,
      display:  'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      gap: 8,
      '& span': {
        flex: 1,
        fontSize: '16x',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#616161'
      }
    },
    copyIcon: {
      '& path': {
        fill: '#000000',
      },
      transition: 'all 0.1s ease-in-out',
      '&:active' : {
        transform: 'scale(1.15)',
      }
    },
    notification: {
      position: 'absolute',
      top: 16,
      left: 24, 
      right: 24,
      backgroundColor: '#212121',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 16,
      padding: '8px 24px',
      boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.3s ease-in-out',
      transform: 'translateY(-20%)',
      opacity: 0,

      '& span': {
        flex: 1,
        marginLeft: 16,
        fontSize: '14px',
        fontWeight: 500,
        display: 'block',
        textAlign: 'left'
      }
      
    },
    notificationOpen: {
      transform: 'translateY(0%)',
      opacity: 1,

    }
  }),
  {
    name: 'WarningWebView',
  },
)(({ classes, setClose, ...props }) => {
  const {params} = usePaymentContext()
  const { rudderStack} = useRudderStack()  
  const [showNotification, setShowNotification] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const link = `${config.paymentLinkUI}/${params.handle}/${params.amount}`
  const copyHandle = () => {
    rudderStack?.track('Payment Link - WebView-iOS Warning - Copy Link')
    navigator.clipboard.writeText(link)
    setShowNotification(true)
    clearTimeout(timeoutId)
    setTimeoutId(setTimeout(() => {
      setShowNotification(false)
    }, 4000))
  }
  return (
    <div className={classes.positionBox}>
      <div className={classes.textContainer}>
        <img src={anotherBrowserImage.src} width={120} height={120} lowsrc={bannerImage.blurDataUrl} alt="" />
        <h3 className={classes.warningTitle}>Utilize outro navegador</h3>
        <h4 className={classes.warningText}>Este navegador não suporta a<br/> operação. Para sua segurança<br/>use um navegador externo</h4>

        <div className={classes.fakeInput}>
          <span>{link}</span>
          <CopyIcon className={classes.copyIcon} onClick={copyHandle} />
        </div>
      </div>
      
      <div className={classes.inputContainer}>
        <span className={classes.button} onClick={copyHandle}>Copiar link</span>
      </div>
      <div className={`${classes.notification} ${showNotification && classes.notificationOpen} ` }>
        <CheckIcon width={24} height={24} />
        <span>Link copiado. Realize a operação em outro navegador</span>
      </div>
    </div>
  )
})
export const ThirdPersonView = withStyles(
  ({ theme, ...props }) => ({
    root: {
      'font-family': 'CeraPro, -apple-system'
    },
    box: {
      minWidth: 320,
      maxWidth: 420,
    },
    title: {
      marginTop: 24,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '125%' ,
      color: '#F5F5F5'
    },
    subtitle: {
      marginTop: 8,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '137%' ,
      color: '#9E9E9E'
    }
   
  }),
  {
    name: 'ThirdPersonView',
  },
)(({ classes, setClose, ...props }) => {
  return (
    <div className={classes.box}>
      <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 11.75C3.5 7.19321 7.19321 3.5 11.75 3.5C16.3068 3.5 20 7.19321 20 11.75C20 16.3068 16.3068 20 11.75 20C7.19321 20 3.5 16.3068 3.5 11.75ZM11.75 2C6.36479 2 2 6.36479 2 11.75C2 17.1352 6.36479 21.5 11.75 21.5C17.1352 21.5 21.5 17.1352 21.5 11.75C21.5 6.36479 17.1352 2 11.75 2ZM16.2803 10.2803C16.5732 9.98744 16.5732 9.51256 16.2803 9.21967C15.9874 8.92678 15.5126 8.92678 15.2197 9.21967L10.75 13.6893L8.28033 11.2197C7.98744 10.9268 7.51256 10.9268 7.21967 11.2197C6.92678 11.5126 6.92678 11.9874 7.21967 12.2803L10.2197 15.2803C10.5126 15.5732 10.9874 15.5732 11.2803 15.2803L16.2803 10.2803Z" fill="#F5F5F5"/>
      </svg>
      <h3 className={classes.title}>Quase lá, logo daremos uma resposta</h3>
      <div className={classes.subtitle}>Em breve você vai receber uma mensagem falando se a sua transação foi aprovada</div>
      
    </div>
  )
})