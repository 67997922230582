import { Box, Button } from "@material-ui/core";
import ELEMENT_TEST_ID from "../../../playwright/support/elements";

import { isMobile } from 'react-device-detect'
import { WalletContainer } from "../Wallet/WalletContainer";
import { usePaymentContext } from "context/PaymentContext";
import useWalletPay, { useWalletPayStore } from "libs/useWalletPay";
import { useShallow } from "zustand/react/shallow";

export function PayContainer({handlePay}) {
  const { product_allowed } = usePaymentContext();
  useWalletPay()
  const {loading, showWallet} = useWalletPayStore(useShallow((state) => ( {loading: state.loading, showWallet: state.showWallet})));
  
  const showButton = product_allowed && product_allowed['wallet-pay'] && showWallet && !loading && isMobile
    
  return <>
    <Box className={`pay-container ${loading ? 'loading' : ''}`}>
      {
        showButton && <WalletContainer />
      }
      {/* { loading && <Button > Pagar com </Button> } */}
      <Button className={showButton || loading ? 'secundary' : ''} onClick={handlePay} data-testid={ELEMENT_TEST_ID.payNowButton}>
        Pagar com cartão
      </Button>
    </Box>
  </>;
}
